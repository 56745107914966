import React from "react";
import { Box } from "@chakra-ui/react";
import { useIntl } from "react-intl";

const ReclamationSAV = () => {
  const { warranty } = useIntl().messages;

  return (
    <>
      <Box p={4} className="container">
        <h1>{warranty.title}</h1>
        <>
          <p className="text-center">
            <strong>
              {warranty.important}
              <a href={warranty.important_sav_link} class="text-underlined">
                {warranty.important_sav}
              </a>
              {warranty.important_outro}
            </strong>
          </p>

          <h2>{warranty.policy.title}</h2>
          {warranty.policy.paragraphs.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
          <ul>
            {warranty.policy.list.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
          <p>
            <em>
              *{warranty.policy.note.text}
              <a
                href={warranty.policy.note.link}
                target="_blank"
                rel="noopener noreferrer"
                data-link-type="external"
                data-link-label="https://www.picture-organic-clothing.com/fr/service-apres-vente"
              >
                {warranty.policy.note.link}
              </a>
            </em>
          </p>
          <p>{warranty.policy.paragraph_2}</p>
          <h2>{warranty.conditions.title}</h2>
          <p>
            À la suite de l’expiration du délai légal de garantie, et si votre
            produit fait partie de la liste ci-dessus des produits pris en
            charge, il vous sera demandé de remplir quelques conditions.
          </p>

          {warranty.conditions.list.map((item, index) => (
            <p key={index}>
              {index + 1}.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item}
            </p>
          ))}
          <p>
            <strong>{warranty.conditions.non_covered.title}</strong>
          </p>
          <ul>
            {warranty.conditions.non_covered.list.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
          {warranty.conditions.non_covered.paragraphs.map(
            (paragraph, index) => (
              <p key={index}>{paragraph}</p>
            )
          )}
          <p>
            <strong>{warranty.conditions.setup.title}</strong>
          </p>
          {warranty.conditions.setup.paragraphs.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
          <p>
            <strong>{warranty.conditions.shipment.title}</strong>
          </p>
          <p>{warranty.conditions.shipment.intro}</p>
          <p>
            <a
              href={warranty.conditions.shipment.link}
              target="_blank"
              rel="noreferrer"
            >
              {warranty.conditions.shipment.link}
            </a>
          </p>
          <p>{warranty.conditions.shipment.subtitle}</p>
          <ul>
            <li>
              {warranty.conditions.shipment.list[0].text}
              <em>{warranty.conditions.shipment.list[0].note}</em>
            </li>
            {warranty.conditions.shipment.list[1].map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
          {warranty.conditions.shipment.paragraphs.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
          <strong>{warranty.conditions.alternatives.strong_title}</strong>
          <p>{warranty.conditions.alternatives.title}</p>
          <ul>
            {warranty.conditions.alternatives.list.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
          <p>
            {warranty.conditions.alternatives.outro}
            <a
              href="https://fra01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.google.com%2Fmaps%2Fd%2Fu%2F0%2Fedit%3Fmid%3D1tXWoJGkhT3PPZcm6fSYz52WvUr7nCzA%26ll%3D36.67714848106535%252C0%26z%3D3&data=05%7C02%7Cfabien.v%40picture-organic-clothing.com%7C0a7925766f854f59b1e208dd4b4ff7a7%7C25664b9b8cdd483094dd16c2eca8d0ac%7C0%7C0%7C638749529022312610%7CUnknown%7CTWFpbGZsb3d8eyJFbXB0eU1hcGkiOnRydWUsIlYiOiIwLjAuMDAwMCIsIlAiOiJXaW4zMiIsIkFOIjoiTWFpbCIsIldUIjoyfQ%3D%3D%7C0%7C%7C%7C&sdata=eqCUOsnk3JJfjSsEEmxNfH%2BJCm%2Bq2ab%2FfFVYsdRsZdU%3D&reserved=0"
              target="_blank"
              rel="noreferrer"
            >
              {warranty.conditions.alternatives.outro_link_text}
            </a>
          </p>
          <strong>
            <i>{warranty.conditions.alternatives.quote}</i>
          </strong>
        </>
      </Box>
    </>
  );
};

export default ReclamationSAV;
